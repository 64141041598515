import React, { useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { API, BEARER } from "../../constant";
import { useEffect } from "react";
import { getToken } from "../../helpers";
import { fetchWithAuth } from "./../../api/fetchWithAuth"

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [permissionData, setPermissionData] = useState();

  const authToken = getToken();

  const fetchRolePermissions = async (roleId, token) => {
    const response =  await fetchWithAuth(`${API}/users-permissions/roles/${roleId}`);
   
    if (response.ok) {
      const roleData = await response.json();
      return roleData.role.permissions;
    }
    throw new Error('Failed to fetch role permissions');
  };

  const fetchUser = async () => {
    const response = await fetchWithAuth(`${API}/users/me?populate=role`);

    if (response.ok) {
      const user = await response.json();
      return user;
    }
    throw new Error('Failed to fetch user data');
  };
  

  const fetchLoggedInUser = async (token) => {
    setIsLoading(true);
    fetchUser()
      .then(user => {
        setUserData(user);
        return fetchRolePermissions(user.role.id, token);
      })
      .then(fetchedPermissions => {
        setPermissionData(fetchedPermissions);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching permissions:', error);
        setIsLoading(false);
      });
  };

  const handleUser = (user) => {
    setUserData(user);
  };

  const handlePermission = (user) => {
    setPermissionData(user);
  };

  useEffect(() => {
    if (authToken) {
      fetchLoggedInUser(authToken);
    }
  }, [authToken]);

  return (
    <AuthContext.Provider
      value={{ user: userData, permission: permissionData, setPermission: handlePermission, setUser: handleUser, isLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;