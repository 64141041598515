import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import './AngleCheckbox.css';

const AngleCheckbox = ({ checked, onChange, text }) => {
  const handleCheckboxChange = () => {
    onChange(!checked);
  };

  return (
    <div className="custom-checkbox">
      <label className="checkbox-label">
        {text}
        <input
          type="checkbox"
          value={checked}
          onChange={handleCheckboxChange}
          className="checkbox-input"
        />
        <span className={`checkbox-angle ${checked ? 'checked' : ''}`}>
          <FontAwesomeIcon icon={checked ? faAngleUp  : faAngleDown} />
        </span>
      </label>
    </div>
    
  );
};
export default AngleCheckbox;