import React, { Component, useEffect } from 'react';
import { get_config_udg, get_config_gou, get_config_3min } from "./config.js";
import { on_update_udg_callback, on_update_3min_callback, on_update_gou_callback } from "./updateCharLogic.js";
import {
  Content, Row, Col, Inputs, Button,
} from 'adminlte-2-react';

import { Line, Bar } from "react-chartjs-2";
import { ButtonToolbar } from 'react-bootstrap';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import moment from 'moment';
import { CENTRIFUGE_URL, API } from "../../constant";
import { Interaction, LineController } from 'chart.js'
import Interpolate from './interpolate.js';
import zoomPlugin from "chartjs-plugin-zoom";
import { Centrifuge } from 'centrifuge'
import annotationPlugin from "chartjs-plugin-annotation";
import DeviationLog from '../../components/Modal/DeviationLog'
import UdgkLog from '../../components/Modal/UdgkLog'
import UdgLog from '../../components/Modal/UdgLog'
import ModalDialog from '../../components/Modal/ModalDialog'
import UdgkForm from '../../components/Modal/UdgkForm'
import UdgForm from '../../components/Modal/UdgForm'
import { ResizableBox } from 'react-resizable'
import "react-resizable/css/styles.css";
import { removeToken, hasPermission } from "../../helpers";
import { useAuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { fetchWithAuth } from "./../../api/fetchWithAuth"

Chart.register(CategoryScale, zoomPlugin, annotationPlugin);

Number.prototype.twodig = function () {
  return Math.round(this * 100) / 100;
}

String.prototype.twodig = function () {
  return Math.round(this * 100) / 100;
}

Interaction.modes.interpolate = Interpolate
const getLabelAndValue = LineController.prototype.getLabelAndValue;
LineController.prototype.getLabelAndValue = function (index) {
  if (index === -1) {
    const meta = this.getMeta();
    const pt = meta._pt;
    const vScale = meta.vScale;
    const iScale = meta.iScale;

    return {
      label: iScale.getLabelForValue(iScale.getValueForPixel(pt.x)),
      value: vScale.getValueForPixel(pt.y).twodig()
    };
  }
  return getLabelAndValue.call(this, index);
}


// Plugin to draw the indicators
Chart.register({
  id: 'indicators',
  afterDraw(chart) {
    const metas = chart.getSortedVisibleDatasetMetas();
    for (let i = 0; i < metas.length; i++) {
      const meta = metas[i];
      if (meta._pt) {
        meta._pt.draw(chart.ctx);
      }
    }
  },
  afterEvent(chart, args) {
    if (args.event.type === 'mouseout') {
      const metas = chart.getSortedVisibleDatasetMetas();
      for (let i = 0; i < metas.length; i++) {
        metas[i]._pt = null;
      }
      args.changed = true;
    }
  }
});

const {
  Date: Datepicker,
} = Inputs;


function withHooks(WrappedComponent) {
  return function(props) {
    const { permission, isLoading, setUser, user } = useAuthContext();
    const navigate = useNavigate();

    useEffect(() => {
      if (!isLoading) {
         if (!permission) {
          removeToken();
          setUser(undefined);
          navigate("/signin");
         }
      }
    }, [permission, isLoading]);


    return (
      <WrappedComponent isLoading={isLoading} permissions={permission} {...props} />
    );
  };
}



class Main extends Component {
  constructor(props) {
    let udgk = [], facts = [], pbrs = [], udg = [], rt3mins = [], rt3mins_labels = [];
    let mid3min = 90;
    super(props);
    this.handleResetZoom = this.handleResetZoom.bind(this);

    this.toggleConfirmDialog = this.toggleConfirmDialog.bind(this);
    this.onHideUdgForm = this.onHideUdgForm.bind(this);
    this.showResult = this.showResult.bind(this);


    this.chartReference = React.createRef();
    this.areaChart3MinReference = React.createRef();
    this.areaChartGouReference = React.createRef();
    this.centrifugeSub = null;
    this.centrifuge = null;
    this.grdata = {};

    this.state = {
      showResult: false,
      showModalDeviationLog: false,
      showModalUdgkLog: false,
      showModalUdgLog: false,
      showModalUdgkForm: false,
      showModalUdgForm: false,
      showConfirmDialogParent: false,
      itemId: "",
      title: "",
      temperatureValue: "н/д",
      token: "",
      pid: this.props.match.params.id,
      responseDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      requestDate: moment().format("YYYY-MM-DD"),
      date: moment().format("YYYY-MM-DD"),
      areaChartGou: {
        labels: [''],
        datasets: [
          {
            label: 'Факт',
            axis: 'y',
            data: [0],
            fill: false,
            borderColor: '#00770099',
            backgroundColor: '#00770099',
          },
          {
            label: '2',
            axis: 'y',
            data: [1000],
            fill: false,
            borderColor: '#8a8a8a33',
            backgroundColor: '#8a8a8a33',
          },
        ]
      },
      areaChart3MinOption: get_config_3min(mid3min),
      areaChart3Min: {
        labels: rt3mins_labels,
        datasets: [
          {
            label: 'Факт',
            data: rt3mins,
            fill: true,
            xAxisID: 'x',
            tension: 0.5,
            borderColor: '#00000044',
            backgroundColor: '#00000044',
            hoverBackgroundColor: '#00000044',
            borderWidth: 0,
            categoryPercentage: 1.1,
            spanGaps: true
          }
        ],
      },
      chart: {
        labels: '',
        datasets: [
          {
            'label': 'УДГК',
            'data': udgk,
            'fill': false,
            xAxisID: 'x',
            tension: 0,
            borderColor: '#336699',
            borderWidth: 2
          },
          {
            'label': 'УДГ',
            'data': udg,
            'fill': false,
            xAxisID: 'x',
            tension: 0,
            borderColor: '#0099ff',
            borderWidth: 2
          },
          {
            'label': 'Факт',
            'data': facts,
            'fill': false,
            xAxisID: 'x',
            tension: 0,
            borderColor: '#ff3300',
            borderWidth: 2,
            spanGaps: true
          },
          {
            'label': 'ПБР',
            'data': pbrs,
            'fill': false,
            xAxisID: 'x',
            tension: 0,
            borderColor: '#ff9900',
            borderWidth: 2
          },
        ],
      }
    };
  }

  toggleConfirmDialog() {
    this.setState(state => ({
      showConfirmDialogParent: !state.showConfirmDialogParent
    }));
  }

  showResult() {
    this.setState(state => ({
      showResult: true
    }));
  }

  onHideUdgForm() {
    this.setState(state => ({
      showModalUdgForm: false,
      showResult: false
    }));
  }

  async centrifugeLoad() {
    if (this.centrifugeSub) {
      this.centrifugeSub.unsubscribe();
    }

    if (this.centrifuge) {
      this.centrifuge.disconnect();
    }

    const token = await this.getTokenCentrifugo();
    this.centrifuge = new Centrifuge(CENTRIFUGE_URL, { token: token });
    this.centrifugeSub = this.centrifuge.newSubscription('channel' + this.state.itemId);

    // React on `item` channel real-time publications.
    this.centrifugeSub.on('publication', message => {
      this.handleMessage(message);
    });
  }

  handleMessage(ctx) {
    if (typeof ctx.data['reload_client'] != 'undefined') {
      window.location.reload();
    }

    this.charts_update_rt_data(ctx);
    this.updateTemperature(ctx);
    this.charts_update_plan_data(ctx);
  }

  charts_update_plan_data(ctx) {

    if (typeof ctx.data?.plan == 'undefined') {
      return;
    }

    const chart = this.chartReference.current;

    chart.data.datasets[0].data = ctx.data.plan.udgk;
    chart.data.datasets[1].data = ctx.data.plan.udg;
    chart.data.datasets[3].data = ctx.data.plan.pbr;

    chart.update();
  }

  charts_update_rt_data(ctx) {

    // not our data
    if (typeof ctx.data?.fact?.prod == 'undefined') {
      return;
    }
    this.grdata.rt_prev = this.grdata.rt;

    this.grdata.rt = ctx.data;

    var dt_prev = this.grdata?.rt_prev?.dt;
    var dt = this.grdata?.rt?.dt;

    if (typeof dt_prev != 'undefined' && dt_prev !== dt) {
      this.setState({
        date: dt,
      });
      this.fetchData()
      return;
    }

    const chart = this.chartReference.current;
    const chart3min = this.areaChart3MinReference.current;
    const chartGou = this.areaChartGouReference.current;

    on_update_udg_callback(chart, this.grdata.rt, this.grdata.station);
    on_update_3min_callback(chart3min, this.grdata.rt, this.grdata.station);
    on_update_gou_callback(chartGou, this.grdata.rt, this.grdata.station, "y");

    chart.update();
    chart3min.update();
    chartGou.update();
  }


  updateTemperature(ctx) {
    // not our data
    if (typeof ctx.data?.fact?.temp == 'undefined') {
      return;
    }

    this.setState({
      temperatureValue: ctx.data.fact.temp,
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.pid !== this.state.pid) {
      await this.fetchTppUnits();
      await this.centrifugeLoad();
      await this.fetchData();
    }
  }

  async componentDidMount() {
    await this.fetchTppUnits();
    await this.centrifugeLoad();
    await this.fetchData();
  }

  componentWillUnmount() {
    if (this.centrifugeSub) {
      this.centrifugeSub.unsubscribe();
    }

    if (this.centrifuge) {
      this.centrifuge.disconnect();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      const { id } = nextProps.match.params;
      this.setState({
        pid: id,
      });
    }
  }

  drawStats(data) {
    var station = this.grdata.station = data.station;
    var bucket = data.current_stats;

    this.energyView(bucket);

    if (station.id > 0) {
      this.setState({
        responseDate: data.response_date,
        requestDate: data.request_date,
      });
    }
    const chart = this.chartReference.current;
    if (data.current_date === data.request_date) {

      const xMin = new Date();
      xMin.setHours(0, 0, 0, 0);

      const xMax = new Date();
      xMax.setHours(new Date().getHours(), 0, 0, 0);

      chart.options.plugins.annotation.annotations.pbr = {
        type: 'box',
        backgroundColor: '#8a8a8a11',
        xMin: moment(xMin).format("YYYY-MM-DD HH:mm:ss"),
        xMax: moment(xMax).format("YYYY-MM-DD HH:mm:ss"),
      };
      this.centrifugeSub.subscribe();
      this.centrifuge.connect();
    } else {
      this.centrifugeSub.unsubscribe();
      this.centrifuge.disconnect();
      chart.options.plugins.annotation.annotations.pbr = null;
    }
  }

  getTokenCentrifugo = async () => {
    try {
      const response = await fetchWithAuth(`${API}/centrifugo`);
      const responseData = await response.json();
      return responseData.token;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData = async () => {
    try {
      const date = moment(this.state.date).format("YYYY-MM-DD");
      const response = await fetchWithAuth(`${API}/enery-stats-khps?pid=${this.state.pid}&date=${date}`);
      const responseData = await response.json();
      this.drawStats(responseData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchTppUnits = async () => {
    try {
      const response = await fetchWithAuth(`${API}/units?filters[id][$eq]=${this.state.pid}`);
      const responseData = await response.json();
      this.setState({
        itemId: responseData.data[0].attributes.ID_Modbus,
        title: `Блок ${responseData.data[0].id}`,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  


  handleResetZoom(event) {
    if (this.chartReference && this.chartReference.current) {
      if (event.button === 0) {
        this.chartReference.current.options.scales.x.time.unit = 'hour';
        this.chartReference.current.resetZoom();
      }
    }
  }

  energyView(bucket) {

    let udgk = [], facts = [], pbrs = [], udg = [], rt3mins = [], rt3mins_labels = [];

    let gou_mode = 'y';

    let maxv = -1000000000, minv = 1000000000, mid3min = 90;

    pbrs = bucket.pbr;
    udgk = bucket.udgk;
    udg = bucket.udg;

    for (const k in bucket.pbr) {

      facts.push({
        x: k,
        y: bucket.rt[k]
      });

      if (bucket.pbr[k] < minv) {
        minv = bucket.pbr[k];
      }

      if (bucket.pbr[k] > maxv) {
        maxv = bucket.pbr[k];
      }

      if (bucket.udgk[k] < minv) {
        minv = bucket.udgk[k];
      }

      if (bucket.udgk[k] > maxv) {
        maxv = bucket.udgk[k];
      }

      if (bucket.udg[k] < minv) {
        minv = bucket.udg[k];
      }

      if (bucket.udg[k] > maxv) {
        maxv = bucket.udg[k];
      }

    }

    var max3min = -1000000000, min3min = 1000000000;

    var idx = 0;

    for (const k in bucket.rt3min) {

      rt3mins[idx] = bucket.rt3min[k];
      var minl = k.substring(6, 8);
      var mminl = k.substring(7, 8);
      var hminl = k.substring(0, 5);
      rt3mins_labels[idx] = minl == '00' ? hminl : (mminl == '0' ? minl : '');

      if (bucket.rt3min[k] < min3min) {
        min3min = bucket.rt3min[k];
      }

      if (bucket.rt3min[k] > max3min) {
        max3min = bucket.rt3min[k];
      }

      idx++;
    }

    mid3min = Math.round((max3min + min3min) / 2);


    const areaChart3Min = this.areaChart3MinReference.current;
    areaChart3Min.data = {
      labels: rt3mins_labels,
      datasets: [
        {
          label: 'Факт',
          data: rt3mins,
          fill: true,
          xAxisID: 'x',
          tension: 0.5,
          borderColor: '#00000044',
          backgroundColor: '#00000044',
          hoverBackgroundColor: '#00000044',
          borderWidth: 0,
          categoryPercentage: 1.1,
          spanGaps: true
        }
      ]
    }
    areaChart3Min.options = get_config_3min(mid3min);
    areaChart3Min.update();

    const areaChartGou = this.areaChartGouReference.current;
    areaChartGou.data = {
      labels: [''],
      datasets: [
        {
          label: 'Факт',
          axis: 'y',
          data: [0],
          fill: false,
          borderColor: '#00770099',
          backgroundColor: '#00770099',
        },
        {
          label: '2',
          axis: 'y',
          data: [1000],
          fill: false,
          borderColor: '#8a8a8a33',
          backgroundColor: '#8a8a8a33',
        },
      ]
    }
    areaChartGou.options = get_config_gou(gou_mode, minv, maxv);
    areaChartGou.update();

    const chart = this.chartReference.current;
    chart.data = {
      datasets: [
        {
          'label': 'УДГК',
          'data': udgk,
          'fill': false,
          xAxisID: 'x',
          tension: 0,
          borderColor: '#336699',
          borderWidth: 2
        },
        {
          'label': 'УДГ',
          'data': udg,
          'fill': false,
          xAxisID: 'x',
          tension: 0,
          borderColor: '#0099ff',
          borderWidth: 2
        },
        {
          'label': 'Факт',
          'data': facts,
          'fill': false,
          xAxisID: 'x',
          tension: 0,
          borderColor: '#ff3300',
          borderWidth: 2,
          spanGaps: true
        },
        {
          'label': 'ПБР',
          'data': pbrs,
          'fill': false,
          xAxisID: 'x',
          tension: 0,
          borderColor: '#ff9900',
          borderWidth: 2
        },
      ]
    };
    chart.options = get_config_udg(minv, maxv);
    chart.update();
  }

  render() {
    const { permissions, isLoading } = this.props;

    return (
      <Content homeRoute="/" title={this.state.title} subTitle="">
        <ModalDialog
          title={`${this.state.title} :: Часовое отклонение за ${this.state.date}`}
          show={this.state.showModalDeviationLog}
          onHide={() => { this.setState({ showModalDeviationLog: false }); }}
        >
          <DeviationLog itemId={this.state.itemId} period={this.state.date} />
        </ModalDialog>
        <ModalDialog
          title={`${this.state.title} :: Лог комманд УДГК за ${this.state.date}`}
          show={this.state.showModalUdgkLog}
          onHide={() => { this.setState({ showModalUdgkLog: false }); }}
        >
          <UdgkLog itemId={this.state.itemId} period={this.state.date} />
        </ModalDialog>
        <ModalDialog
          modalSize="lg"
          title={`Регистрация команд УДГ: ${this.state.title}`}
          show={this.state.showModalUdgForm}
          onHide={this.onHideUdgForm}
          modalFooter={this.state.showResult ?
            <div>
              <Button className="center-block" text="Закрыть окно" type="info" onClick={this.onHideUdgForm} />
            </div>
            :

            <div>
              <Button text="Отменить" onClick={this.onHideUdgForm} pullLeft />
              <Button text="Отправить" onClick={this.toggleConfirmDialog} type="info" pullRight />
            </div>
          }

        >
          <UdgForm itemId={this.state.itemId} onHideConfirmDialog={this.toggleConfirmDialog} showResult={this.showResult} showConfirmDialog={this.state.showConfirmDialogParent} udgUnitTitle="Регистрация команд УДГ : Краснодарская ТЭЦ" />
        </ModalDialog>
        <ModalDialog
          modalSize="lg"
          title={`Относительная и абсолютная корректировка УДГК: ${this.state.title} `}
          show={this.state.showModalUdgkForm}
          onHide={() => { this.setState({ showModalUdgkForm: false }); }}
        >
          <UdgkForm itemId={this.state.itemId}/>
        </ModalDialog>
        <ModalDialog
          title={`${this.state.title} :: Лог комманд УДГ за ${this.state.date}`}
          show={this.state.showModalUdgLog}
          onHide={() => { this.setState({ showModalUdgLog: false }); }}
        >
          <UdgLog itemId={this.state.itemId} period={this.state.date} />
        </ModalDialog>

        <Row>
          <Col xs={12}>
            <div className="box">
              <div className="box-header">
                <h2 className="page-header">{"Выгрузка сформирована " + this.state.responseDate + " по дате " + this.state.requestDate}</h2>
                <Row>
                  <Col md={12}>
                    <div className="form-horizontal">
                      <Col md={2}>
                        <Datepicker
                          label="Дата"
                          className="datepicker"
                          labelPosition="left"
                          iconLeft="fas-calendar"
                          format="YYYY-MM-DD"
                          inputIconPosition="before"
                          dateProps={{ "isOutsideRange": () => false, "numberOfMonths": 1 }}
                          value={this.state.date}
                          onChange={(event) => {
                            const { target: { value: value } } = event;
                            this.setState({ date: value });
                          }}
                        />
                      </Col>
                      <Col md={2}>
                        <Button type="success" icon="fas-play" text="Показать данные" onClick={() => this.fetchData()} />
                      </Col>
                      <Col md={8}>
                        <ButtonToolbar className="pull-right">
                          <div className="btn bg-purple"> <span> {this.state.temperatureValue}</span> {" °C"} </div>
                          { !isLoading && hasPermission(permissions, 'api::deviation-log', 'deviation-log', 'getLog') &&
                          <div className="btn-group">
                            <Button type="danger" text="Отклонения" >
                              <a onClick={() => { this.setState({ showModalDeviationLog: true }); }} href="#">Почасовые</a>
                            </Button>
                          </div>
                          }
                          {!isLoading && hasPermission(permissions, 'api::udgk-form', 'udgk-form', 'insertCommand') &&
                          <div className="btn-group">
                            <Button type="primary" text="Регистрация команд" >
                              <a onClick={() => { this.setState({ showModalUdgForm: true }); }} href="#">УДГ</a>
                              <a onClick={() => { this.setState({ showModalUdgkForm: true }); }} href="#">УДГК</a>
                            </Button>
                          </div>
                          }
                          {!isLoading && hasPermission(permissions, 'api::udg-log', 'udg-log', 'getLog') &&
                            <div className="btn-group">
                              <Button type="info" text="Лог команд" >
                                <a onClick={() => { this.setState({ showModalUdgLog: true }); }} href="#">УДГ</a>
                                <a onClick={() => { this.setState({ showModalUdgkLog: true }); }} href="#">УДГК</a>
                              </Button>
                            </div>
                          }
                        </ButtonToolbar>
                      </Col>
                    </div>
                  </Col>
                  <Col md={8}></Col>
                </Row>
                <div>
                  <ResizableBox className="chart box with-border" width={1540} height={506} minConstraints={[360, 360]} style={{ paddingBottom: '30px' }}>
                    <Line options={{ maintainAspectRatio: false }} onDoubleClick={this.handleResetZoom} style={{ height: '100%', width: '99%', margin: 'auto' }} ref={this.chartReference} data={this.state.chart} />
                  </ResizableBox>
                  <Row>
                    <Col md={8}> <div className="chart" style={{ height: '240px', width: '100%', margin: 'auto', position: 'relative' }}>
                      <Bar style={{ height: '240px', width: '100%', margin: 'auto', position: 'relative' }} ref={this.areaChart3MinReference} data={this.state.areaChart3Min}
                        options={this.state.areaChart3MinOption} /></div> </Col>
                    <Col md={4}>  <div className="chart" style={{ width: '100%', margin: 'auto', position: 'relative' }}> <Bar style={{ height: '224px', width: '100%' }} ref={this.areaChartGouReference} data={this.state.areaChartGou}
                    /></div> </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}

export default withHooks(Main);
