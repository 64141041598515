import React, { useEffect, useState } from "react";
import { API } from "../../constant";
import { fetchWithAuth } from "./../../api/fetchWithAuth"

const columns = [
  { title: 'дата', data: 'date' },
  { title: 'УДГ(МВт)', data: 'source' },
  { title: 'Отн. кор. УДГК(%)', data: 'formulaPerc' },
  { title: 'Абс. кор. УДГК(МВт)', data: 'formulaAbs' },
  { title: 'УДГК(МВт)', data: 'value' }
];

function UdgkForm({itemId}) {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await fetchWithAuth(`${API}/udgk-form?itemId=${itemId}`);
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const calcValue = (formula, coeff, sourceValue) => {
    if (formula === 'abs') {
      return coeff;
    }
    else if (formula === 'perc') {
      var iperc = parseInt(coeff);
      var val = Math.round(parseInt(sourceValue) + (parseInt(sourceValue) / 100) * parseInt(iperc));
      return isNaN(iperc) ? false : val;
    }
    return sourceValue;
  }


  const setValue = (e, date, type) => {
    const item = data.find((row) => (row.date === date));

    if (isNaN(e.target.value)) {
      e.target.value = 0;
    }

    if (e.target.value === '') {
      item.formula = "";
      item.coeff = "";
    }
    else {
      item.coeff = e.target.value;
      item.formula = type;
    }


    setData((prevData) =>
      prevData.map((row) => (row.date === date ? item : row))
    );
  }

  const handleBlur = async (e, date, type) => {
    if (e.target.readOnly) {
      return;
    }

    const item = data.find(
      a => a.date === date
    );

    if (e.target.value === '') {
      item.formula = "";
      item.coeff = "";
    }
    else {
      item.coeff = e.target.value;
      item.formula = type;
    }
    const newValue = calcValue(item.formula, item.coeff, item.source);
    if (item.value !== newValue) {
      item.value = newValue;
      await insert(item);
    }

    setData((prevData) =>
      prevData.map((row) => (row.date === date ? item : row))
    );

  };


  const insert = async (data) => {
    const body = {
      udgk_id: itemId,
      dt: data.date,
      formula: data.formula || 'abs',
      coeff_value: data.coeff || 0,
      udg_value: data.source,
      setup_value: data.value,
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    };

    const response = await fetchWithAuth(`${API}/udgk-form`, requestOptions);
  }

  return (
    <div>
      <table class="table table-condensed table-striped table-bordered table-hover">
        <thead>
          <tr>
            <th>Время</th>
            <th>УДГ(МВт)</th>
            <th>Отн. кор. УДГК(%)</th>
            <th>Абс. кор. УДГК(МВт)</th>
            <th>УДГК(МВт)</th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => <tr>
            <td>{item.date}</td>
            <td>{item.source}</td>
            <td> <input onBlur={(e) => handleBlur(e, item.date, 'perc')} onChange={(e) => setValue(e, item.date, 'perc')}
              readOnly={item.formula === 'abs'} className="input-transparent" value={item.coeff == 0 ? "" : item.formula !== 'abs' ? item.coeff : 'X'} />
            </td>
            <td> <input onBlur={(e) => handleBlur(e, item.date, 'abs')} onChange={(e) => setValue(e, item.date, 'abs')}
              readOnly={item.formula === 'perc'} className="input-transparent" value={item.coeff == 0 ? "" : item.formula !== 'perc' ? item.coeff : 'X'} /> </td>
            <td>{item.value}</td>
          </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default UdgkForm;

