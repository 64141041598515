import { removeToken, getToken, isTokenExpired} from "./../helpers";

const handleLogout = () => {
    removeToken();
    window.location.href = '/signin';
  };

export async function fetchWithAuth(url, options = {}) {
  const token = getToken();
  
  if (token && isTokenExpired(token)) {
    handleLogout();
    throw new Error('Token is expired');
  }

  const headers = {
    'Content-Type': 'application/json',
    ...options.headers,
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const response = await fetch(url, {
    ...options,
    headers,
  });

  if (response.status === 401) {
    handleLogout();
    throw new Error('Unauthorized');
  }

  return response;
}