import React, { useEffect, useRef, useState } from "react";
import { API } from "../../constant";
import { fetchWithAuth } from "../../api/fetchWithAuth"
import { useAuthContext } from "../../context/AuthContext";
import {
  Content, Row, SimpleTable, Col, Box, Inputs, Button
} from 'adminlte-2-react';
import moment from 'moment';

import './UnitForm.css';

const {
  Text, Select2, Checkbox
} = Inputs;

const columns2 = [
  { title: '', data: 'protocolName' },
  { title: '', data: 'createdAt' },
  { title: '', data: 'unitsName' },
  { title: '', data: 'name' },
  { title: '', data: 'signalType' },
  { title: '', data: '' },
];

const columns = [
  { title: 'Протокол', data: 'protocolName' },
  { title: 'Время прихода сигнала', data: 'createdAt' },
  { title: 'Блок', data: 'unitsName' },
  { title: 'Имя', data: 'name' },
  { title: 'Тип сигнала', data: 'signalType' },
  { title: 'Код качества', data: '' },
];

const UnitForm = (props) => {
  const wrapperRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(1);
  const unitId = props.match.params.id;
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({ onlyTeleMeasurement: false, protocolType: "" });
  const [dataTable, setDataTable] = useState([]);
  const [redirectId, setRedirectId] = useState("");
  const [dataCandidateTable, setDataCandidateTable] = useState([]);

  const [idModbus, setIdModbus] = useState();
  const [protocolTypes, setProtocolTypes] = useState([]);

  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleChangeIdModbus = (event) => {
    const { name, value } = event.target;
    setIdModbus(value);
  };

  const fetchProtocolTypes = async () => {
    try {
      const response = await fetchWithAuth(`${API}/protocol-types`);
      const data = await response.json();
      const protocolTypes = (data?.data ?? []).map(item => ({ text: item.attributes.title, value: item.id }))
      setProtocolTypes(protocolTypes);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTele = async () => {
    const teleSignals = await fetchTeleSignals();
    const teleMeasurements = await fetchTeleMeasurements();

    const table = [...teleSignals, ...teleMeasurements];
    table.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));

    if (table.length > 0) {
      setDataTable(table);
    }
    else {
      setDataTable([{ protocolName: 'данные отсутствуют' }]);
    }
  }

  const fetchTeleMeasurements = async () => {
    try {
      const response = await fetchWithAuth(`${API}/tele-measurements?populate[0]=units,signalType,protocol&populate[1]=protocol.protocolType`);
      const data = await response.json();
      if (typeof data !== 'undefined' && data.data.length > 0) {
        const teleMeasurementsData = data.data.map(item => ({
          id: item.id,
          key: `${item.id}_measurement`,
          name: item.attributes.name,
          createdAt: moment(item.attributes.createdAt).format("YYYY-MM-DD HH:mm:ss"),
          unitsName: item.attributes.units.data.map(unit => `Блок${unit.id}`).join(),
          signalType: item.attributes?.signalType?.data?.attributes?.title,
          protocolName: item.attributes?.protocol?.data?.attributes?.title,
          type: 'measurement',
          protocolTypeId: item.attributes?.protocol?.data?.attributes?.protocolType?.data?.id,
        }));
        return teleMeasurementsData;
      }

    } catch (error) {
      console.error(error);
    }
  };

  const fetchTeleSignals = async () => {
    try {
      const response = await fetchWithAuth(`${API}/tele-signals?populate[0]=units,signalType,protocol&populate[1]=protocol.protocolType`);
      const data = await response.json();
      if (typeof data !== 'undefined' && data.data.length > 0) {
        const teleSignalsData = data.data.map(item => ({
          id: item.id,
          key: `${item.id}_signal`,
          name: item.attributes.name,
          createdAt: moment(item.attributes.createdAt).format("YYYY-MM-DD HH:mm:ss"),
          unitsName: item.attributes.units.data.map(unit => `Блок${unit.id}`).join(),
          signalType: item.attributes?.signalType?.data?.attributes?.title,
          protocolName: item.attributes?.protocol?.data?.attributes?.title,
          id: item.id,
          type: 'signal',
          protocolTypeId: item.attributes?.protocol?.data?.attributes?.protocolType?.data?.id,
        }));
        return teleSignalsData;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUnit = async () => {
    try {
      const response = await fetchWithAuth(`${API}/units/${unitId}?populate=teleSignals,teleMeasurements`);
      const data = await response.json();
      const teleMeasurementKeys = data.data.attributes?.teleMeasurements?.data.map(item => `${item.id}_measurement`) ?? [];
      const teleSignalKeys = data.data.attributes?.teleSignals?.data.map(item => `${item.id}_signal`) ?? [];

      setIdModbus(data.data.attributes.ID_Modbus);
      const teleMeasurements = dataTable.filter(({ key }) => teleMeasurementKeys.includes(key));
      const teleSignals = dataTable.filter(({ key }) => teleSignalKeys.includes(key));
      setDataCandidateTable([...teleMeasurements, ...teleSignals]);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (unitId) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
    fetchTele();
    fetchProtocolTypes();
  }, [unitId]);


  useEffect(() => {
    if (unitId) {
      fetchUnit();
    }
  }, [dataTable]);


  const handleRemove = (teleKey) => {
    setDataCandidateTable(prev => prev.filter(({ key }) => key !== teleKey));
  };

  const handleAdd = (teleKey) => {
    setDataCandidateTable(prev => [...prev, dataTable.find(({ key }) => key === teleKey)]);
  };

  const handleSave = async () => {
    try {
      const body = {
        data: {
          ID_Modbus: idModbus,
          teleMeasurements: dataCandidateTable.filter(({ type }) => type === 'measurement').map(item => item.id) ?? [],
          teleSignals: dataCandidateTable.filter(({ type }) => type === 'signal').map(item => item.id) ?? [],
        }
      }
      if (isEditing) {
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body)
        };

        const response = await fetchWithAuth(`${API}/units/${unitId}`, requestOptions);
        setRedirectId(unitId);
        if (response.ok) {
          await props.updateMenu();
          await delay(500);
          redirect();
        }
      }
      else {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body)
        };

        const response = await fetchWithAuth(`${API}/units`, requestOptions);

        if (response.ok) {
          const result = await response.json();
          setRedirectId(result.data.id);
          await props.updateMenu();
          await delay(500);
          redirect();
        }
      }
    } catch (error) {
      console.error(error);
    }

  }

  const delay = (milliseconds) => new Promise(resolve => setTimeout(resolve, milliseconds));

  const redirect = () => {
    if (wrapperRef.current) {
      wrapperRef.current.querySelector('a').click();
    }
  };


  const Step2 = () => {
    const handleSetOnlyTeleMeasurement = ({ target: { checked } }) => {
      setFormData((prevFormData) => ({ ...prevFormData, onlyTeleMeasurement: checked }));
    };

    const handleSetOnlyTeleSignal = ({ target: { checked } }) => {
      setFormData((prevFormData) => ({ ...prevFormData, onlyTeleSignal: checked }));
    };

    const handleSetIsEmptyUnit = ({ target: { checked } }) => {
      setFormData((prevFormData) => ({ ...prevFormData, isEmptyUnit: checked }));
    };

    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    let localData = dataTable;

    if (formData.onlyTeleMeasurement) {
      localData = localData.filter(({ type }) => type === 'measurement');
    }

    if (formData.onlyTeleSignal) {
      localData = localData.filter(({ type }) => type === 'signal');
    }

    if (formData.isEmptyUnit) {
      localData = localData.filter(({ unitsName }) => unitsName === '');
    }

    if (formData.protocolType) {
      localData = localData.filter(({ protocolTypeId }) => protocolTypeId === parseInt(formData.protocolType));
    }

    if (dataCandidateTable && dataCandidateTable.length > 0) {
      localData = localData.filter(({ key }) => dataCandidateTable.findIndex(item => item.key === key) === -1)
    }


    return (
      <>
        <Row><Col md={4}> </Col><Col style={{ marginLeft: 20 }} md={3}> <h4> Добавление телеизмерений и телесигналов </h4> </Col> <Col md={5}></Col> </Row>
        <Row><Col md={5}> </Col><Col style={{ marginLeft: 20 }} md={2}> Добавлены к блоку </Col> <Col md={5}></Col> </Row>
        <Row>
          <Col md={3}> </Col>
          <Col md={6}>
            <SimpleTable
              hover
              condensed
              columns={columns2.concat([{
                title: '',
                data: 'key',
                render: key => key && <><Button icon="fa-trash" onClick={() => handleRemove(key)} className="clickable" /> </>,
              }])}
              data={dataCandidateTable} />
          </Col>
          <Col md={3}> </Col>
        </Row>

        <Row>
          <Col md={3}> </Col>
          <Col sm={6}>
            <Box type="default" solid>
              <Row>
                <Col className="without-padding-right" style={{ paddingLeft: '5px'}} sm={2}>
                  <Checkbox value={formData.onlyTeleSignal}
                    onChange={handleSetOnlyTeleSignal}
                    labelPosition="left"
                    labelSm={8}
                    sm={2}
                    label="телесигнал" />
                </Col>
                <Col className="without-padding-right" sm={3}>
                  <Checkbox value={formData.onlyTeleMeasurement}
                    labelSm={7}
                    sm={2}
                    onChange={handleSetOnlyTeleMeasurement}
                    label="телеизмерение"
                    labelPosition="left"
                  />
                </Col>

                <Col className="without-padding-right"  sm={3}>
                  <Checkbox value={formData.isEmptyUnit}
                    onChange={handleSetIsEmptyUnit}
                    labelPosition="left"
                    labelSm={8}
                    sm={1}
                    labelClass="without-padding-right"
                    label="Блок не задан" />
                </Col>

                <Col className="without-padding-right" sm={4}>
                  <Select2
                    placeholder="Стандарт протокола"
                    allowClear
                    size="sm"
                    sm={12}
                    labelSm={0}
                    name="protocolType"
                    options={protocolTypes}
                    value={formData.protocolType}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
            </Box>
          </Col>
          <Col md={3}> </Col>
        </Row>
        <Row><Col md={5}> </Col><Col style={{ marginLeft: 20 }} md={2}> Доступные ТИ и ТС </Col> <Col md={5}></Col> </Row>
        <Row>
          <Col md={3}> </Col>
          <Col md={6}>
            <SimpleTable
              hover
              condensed
              columns={columns.concat([{
                title: '',
                data: 'key',
                render: key => key && <><Button icon="fa-plus" onClick={() => handleAdd(key)} className="clickable" /> </>,
              }])}
              data={localData} />
          </Col>
          <Col md={3}> </Col>
        </Row></>
    );
  };

  return (
    <Content title={isEditing ? "Редактирование блока" : "Добавление блока"}>
      <Box type="primary" title="">
        <div>
          {currentStep === 1 && (
            <Row>
              <Col md={4}>
              </Col>
              <Col md={3}>
                <Text labelSm={4} sm={3} onChange={handleChangeIdModbus} name="idModbus" value={idModbus} inputType="number" placeholder="" label="ID Modbus Terminal" labelPosition="above" />
              </Col>
              <Col md={5}>
              </Col>
            </Row>
          )}
          {currentStep === 2 && (
            <Step2 formData={formData} />
          )}

          <div style={{ marginTop: "20px" }}>
            {currentStep < 2 && <Row><Col md={5}></Col><Col style={{ marginLeft: 20 }} md={2}> <Button size="md" onClick={handleNext} type="primary" text="Далее" /></Col> <Col md={5}></Col> </Row>}

            {currentStep === 2 && (
              <Row>
                <Col md={3}> </Col>
                <Col md={6}>
                  <Button size="md" onClick={handleBack} type="primary" text="Назад" pullLeft />
                  <Button size="md" onClick={handleSave} type="primary" text="Сохранить" pullRight />
                </Col>
                <Col md={3}> </Col>
              </Row>
            )}
          </div>
          {/* Проблема с либой adminlte-2-react она используется react router v5 */}
          <div style={{ display: "none" }} ref={wrapperRef}> <Button to={redirectId && `/main/${redirectId}/`}></Button> </div>
        </div>
      </Box>
    </Content>
  );
}

export default UnitForm;
