import React, { useEffect, useState } from "react";
import { API } from "../../constant";
import { fetchWithAuth } from "./../../api/fetchWithAuth"
import {
  Content, Row, Col, Box, Inputs, Button, DataTable, SmartTable, SimpleTable
} from 'adminlte-2-react';

import ModalDialog from '../../components/Modal/ModalDialog'

const {
  Text, Select, Checkbox
} = Inputs;

const columns = [
  { title: 'Протокол', data: 'title' },
  { title: 'ASDU', data: 'ASDU' },
  { title: 'Адрес порта', data: 'port' },
  { title: 'IP сервера', data: 'ipAddress' },
];

const Protocols = () => {
  const [protocols, setProtocols] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [candidateforDeletion, setCandidateforDeletion] = useState();

  const handleRemove = (id) => {
    setCandidateforDeletion(id)
    toggleConfirmDialog();
  };

  const fetchProtocols = async () => {
    try {
      const response = await fetchWithAuth(`${API}/protocols`);
      const data = await response.json();
      if (typeof data !== 'undefined' && data.data.length > 0) {
        const protocolsData = data.data.map(item => ({
          id: item.id,
          title: item.attributes.title,
          ASDU: item.attributes.ASDU,
          port: item.attributes.port,
          ipAddress: item.attributes.ipAddress
        }));
        setProtocols(protocolsData);
      }
      else {
        setProtocols([{ title: 'протоколы отсутствуют' }]);
      }

    } catch (error) {
      console.error(error);
    }
  };

  const toggleConfirmDialog = () => {
    setShowConfirmDialog(prevState => !prevState);
  }

  useEffect(() => {
    fetchProtocols();
  }, []);

  const removeProtocol = async () => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    };

    const response = await fetchWithAuth(`${API}/protocols/${candidateforDeletion}`, requestOptions);
    if (response.ok) {
      setCandidateforDeletion(null); 
      toggleConfirmDialog();
      fetchProtocols();
    }
  }

  const getText = () => {
    if(!candidateforDeletion) {
      return "";
    }

    return `Вы уверены что хотите удалить протокол ${protocols.find(item => item.id === candidateforDeletion).title}`
  }

  return (
    <Content title="Управление протоколами">
      <ModalDialog
        showPrimaryBox={false}
        modalSize="md"
        title="Подтверждение удаления протокола"
        show={showConfirmDialog}
        onHide={toggleConfirmDialog}
        modalFooter=
        {
          <div className="box-footer">
            <Button text="Отменить" onClick={toggleConfirmDialog} pullLeft />
            <Button text="Подтвердить удаление" onClick={removeProtocol} type="info" pullRight />
          </div>
        }
      >
        <div style={{ marginLeft:10, whiteSpace: 'pre-wrap' }}> {getText()} </div>
      </ModalDialog>
      <Box type="primary" title="">
        <Row>
          <Col md={3}> </Col>
          <Col md={6}>
            <Button className="unstyled-button" to="/protocol/new" text="Создать протокол" icon="fa-plus-circle" />
            <SimpleTable
              hover
              condensed
              columns={columns.concat([{
                title: '',
                data: 'id',
                render: id => id && <><Button icon="fa-trash" onClick={() => handleRemove(id)} className="clickable" /><Button to={`/protocol/edit/${id}`} icon="fa-cogs" className="clickable" /></>,
              }])}
              data={protocols}
            />
          </Col>
          <Col md={3}> </Col>
        </Row>
      </Box>
    </Content>
  );
}

export default Protocols;
