export function on_update_udg_callback(chart, data, station) {
    // not our data
    if (typeof data?.fact?.prod == 'undefined') {
        return;
    }

    chart.data.datasets[2].data[chart.data.datasets[2].data.length] = { x: data.dtms, y: data.fact.prod };

    var canns = chart.options.plugins.annotation.annotations;
    if (typeof canns.pbr != 'undefined') {
        canns.pbr.xMax = data.dtms;
    }

    canns.pbr.xMin = data.dts;

    chart.options.plugins.title.text = station.title + ' :: График ПБР ' + data.pbr + (typeof data.fact.dv['3'] != 'undefined' ? ' :: ' + data.fact.dv['3'] + '%' : '');

}

export function on_update_gou_callback(chart, data, station, gou_mode) {

    // not our data
    if (typeof data?.fact?.prod == 'undefined') {
        return;
    }

    var gouds = chart.data.datasets;

    gouds[0].data[0] = data.fact.prod;
    gouds[1].data[1] = data.udg;

    var reserve = parseFloat(data.reserve);
    var color = reserve > 0 ? '#00770099' : '#ff0000aa';

    gouds[0].borderColor = color;
    gouds[0].backgroundColor = color;

    var anns = chart.options.plugins.annotation.annotations;
    const kValue = gou_mode === 'x' ? 'yValue' : 'xValue'
    const kMin = gou_mode === 'x' ? 'yMin' : 'xMin'
    const kMax = gou_mode === 'x' ? 'yMax' : 'xMax'


    anns.real[kValue] = data.fact.prod;
    anns.real.content = ['Факт: ' + data.fact.prod, 'Резерв: ' + reserve];

    anns.min[kMin] = anns.min[kMax] = anns.min_label[kValue] = anns.min_label.content[0] = data.min;
    anns.max[kMin] = anns.max[kMax] = anns.max_label[kValue] = anns.max_label.content[0] = data.max;
    anns.udg[kMin] = anns.udg[kMax] = anns.udg_label[kValue] = anns.udg_label.content[0] = data.udg;

    chart.options.plugins.title.text = station.title + ' :: Блоки ГОУ ' + (typeof data.fact.dv['1'] != 'undefined' ? ' :: ' + data.fact.dv['1'] + '%' : '');
}

export function on_update_3min_callback(chart, data, station) {

    // not our data
    if (typeof data?.fact?.prod == 'undefined') {
        return;
    }

    if (data['3min_blk_sec'] == 0) {

        var cdate = new Date();

        var d = cdate.getDate();
        var m = cdate.getMonth() + 1;
        var y = cdate.getFullYear();
        var h = cdate.getHours();

        var dateString = y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d) + ' ' + (h <= 9 ? '0' + h : h) + ':00:00';

        var hdate = new Date(dateString);

        var current_block = data['3min_blk_num'];

        var block_time_start = Math.floor(hdate.getTime() / 1000) + current_block * 180;
        var block_time_end = block_time_start + 180;

        var idx = 0;

        chart.data.labels = [];

        for (var t = block_time_start; t < block_time_end; t += 1) {
            var dtt = new Date(t * 1000);
            chart.data.labels[idx] = idx % 60 === 0 ? format_labels_hh_mm(dtt) : (idx % 10 === 0 ? format_labels_ss(dtt) : '');
            chart.data.datasets[0].data[idx] = null;
            idx++;

        }
    }

    chart.data.datasets[0].data[data['3min_blk_sec']] = data.fact.prod;

    var anns3min = chart.options.plugins.annotation.annotations;
    anns3min.current.yMin = data.udg;
    anns3min.current.yMax = data.udg;
    anns3min.min.yMin = data.udg_3min_min;
    anns3min.min.yMax = data.udg_3min_min;
    anns3min.max.yMin = data.udg_3min_max;
    anns3min.max.yMax = data.udg_3min_max;

    chart.options.scales.y.min = data.udg_3min_min - 10;
    chart.options.scales.y.max = data.udg_3min_max + 10;

    chart.options.plugins.title.text = station.title + ' :: Трехминутная выработка' + (typeof data.fact.dv['2'] != 'undefined' ? ' :: ' + data.fact.dv['2'] + '%' : '');

}

function format_labels_hh_mm(dtt) {
    var h = dtt.getHours(), m = dtt.getMinutes();

    return (h <= 9 ? '0' + h : h) + ':' + (m <= 9 ? '0' + m : m);
}

function format_labels_ss(dtt) {
    var s = dtt.getSeconds();

    return (s <= 9 ? '0' + s : s);
}