import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from "../../constant";
import { setToken} from "../../helpers";
import { useAuthContext } from "../../context/AuthContext";

const Popup = ({ message, onClose }) => (
  <div className="popup">
    <div className="popup-content">
      <h3>Error</h3>
      <p>{message}</p>
      <button onClick={onClose}>Close</button>
    </div>
  </div>
);


const AuthCallback = () => {
    const navigate = useNavigate();
    const { setUser } = useAuthContext();
    const [error, setError] = useState(null);


  useEffect(() => {
    const getAccessTokenFromUrl = () => {
      const params = new URLSearchParams(window.location.search);
      return params.get('access_token');
    };

    const fetchJwtToken = async (accessToken) => {
      try {
        const response = await fetch(`${API}/auth/keycloak/callback?access_token=${accessToken}`);
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error.message || 'Network response was not ok');
        }

        setToken(data.jwt);
        setUser(data.user);
        navigate('/');
      } catch (error) {
        console.error('Error fetching JWT token:', error);
        setError(error.message);
      }
    };

    const accessToken = getAccessTokenFromUrl();
    if (accessToken) {
      fetchJwtToken(accessToken);
    }
  }, [navigate]);

  const handleClosePopup = () => {
    setError(null);
    navigate('/');
  };

  return (
    <div>
      <h2>Authenticating...</h2>
      {error && <Popup message={error} onClose={handleClosePopup} />}
    </div>
  );
};

export default AuthCallback;