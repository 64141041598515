import { Route, Navigate, useNavigate, Routes } from "react-router-dom";
import { getToken } from "./helpers";
import Main from "./pages/Main/Main";
import UnitForm from "./pages/Unit/UnitForm";
import Units from "./pages/Unit/Units";
import TeleSignals from "./pages/TeleSignal/TeleSignals";
import Protocols from "./pages/Protocol/Protocols";
import TeleMeasurements from "./pages/TeleMeasurement/TeleMeasurements";
import TeleMeasurementForm from "./pages/TeleMeasurement/TeleMeasurementForm";
import ProtocolForm from "./pages/Protocol/ProtocolForm";
import TeleSignalForm from "./pages/TeleSignal/TeleSignalForm";

import AdminLTE, {
  Footer, Navbar, Sidebar
} from 'adminlte-2-react';
import SignIn from "./pages/SignIn/SignIn";
import AuthCallback from "./pages/AuthCallback/AuthCallback";
import { useAuthContext } from "./context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { removeToken, isTokenExpired, hasPermission } from "./helpers";
import React, { useEffect, useState } from "react";
import { API } from "./constant";
import { fetchWithAuth } from "./api/fetchWithAuth"
import moment from 'moment';
import ControlSidebar from "./components/Menu/ControlSidebar"
const { Header, Item } = Sidebar;




const App = () => {
  const [menuItems, setMenuItems] = useState([]);
  const navigate = useNavigate();

  const { user, permission, setUser, isLoading } = useAuthContext();

  const handleLogout = () => {
    removeToken();
    setUser(null);
    navigate("/signin");
  };

  const fetchMenu = async () => {
    try {
      const authToken = getToken();
      if (authToken == null) {
        setMenuItems([]);
        return;
      }

      const response = await fetchWithAuth(`${API}/units`)
      const data = await response.json();
      const menu = data.data.map(item => ({
        id: item.id,
        text: `Блок ${item.id}`,
        createdAt: moment(item.attributes.createdAt).format("YYYY-MM-DD HH:mm:ss"),
      }))

      menu.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));
      setMenuItems(menu);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const token = getToken();
    if (token && isTokenExpired(token)) {
      handleLogout();
    }
    fetchMenu();

  }, [user]);

  const [controlSidebarOpen, setControlSidebarOpen] = useState(false);

  const toggleControlSidebar = () => {
    setControlSidebarOpen(!controlSidebarOpen);
  };

  useEffect(() => {
    if (!isLoading && !user && !permission) {
      handleLogout();
    }
  }, [permission, user, isLoading]);

  return (
    <Routes>
      <Route path="*" element={isLoading || user ? (
        <AdminLTE title={["K", "ТЭЦ"]} titleShort={["K", "ТЭЦ"]} browserTitle="КТЭЦ" theme="red">
          <Sidebar.Core>
            <Header text="ОСНОВНЫЕ ПОКАЗАТЕЛИ" />
            {menuItems.map(p => <Item icon="fa-book" text={p.text} to={`/main/${p.id}`} />)}
          </Sidebar.Core>

          <Navbar.Core>
            <li>
              <a onClick={handleLogout}>
                {isLoading ? <>Loading...</> : <span class="hidden-xs">{user.name}</span>}
                <FontAwesomeIcon
                  icon={["fas", "sign-out-alt"]}
                />
              </a>
            </li>
            {isLoading ? <></> : hasPermission(permission, 'api::unit', 'unit', 'create') && <Navbar.Entry onClick={toggleControlSidebar}
              icon="fas-cogs"
            >

              <ControlSidebar isSidebarOpen={controlSidebarOpen}>
              </ControlSidebar>

            </Navbar.Entry>
            }
          </Navbar.Core>

          <Main path="/main/:id" exact />
          <UnitForm updateMenu={fetchMenu} path="/unit/new" exact />
          <UnitForm updateMenu={fetchMenu} path="/unit/edit/:id" />

          <Protocols path="/protocols/" exact />
          <TeleSignals path="/telesignals/" exact />
          <Units updateMenu={fetchMenu} path="/units/" exact />
          <ProtocolForm path="/protocol/new" />
          <ProtocolForm path="/protocol/edit/:id" />
          <TeleMeasurementForm path="/telemeasurement/new" />
          <TeleMeasurementForm path="/telemeasurement/edit/:id" />
          <TeleSignalForm path="/telesignal/new" />
          <TeleSignalForm path="/telesignal/edit/:id" />
          <TeleMeasurements path="/telemeasurements/" exact />
        </AdminLTE>) : <Navigate to="/signin" />
      }>
      </Route>
      <Route path="/auth/callback" element={<AuthCallback />} />
      <Route path="/signin" element={<SignIn />} />
    </Routes>
  );
};


export default App;
