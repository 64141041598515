import { PointElement } from 'chart.js'

// Options for the indicators
const indicatorOptions = {
    radius: 4, borderWidth: 1, borderColor: 'magenta', backgroundColor: 'transparent'
};

export default function (chart, e) {

    const x = e.x;
    const items = [];
    const metas = chart.getSortedVisibleDatasetMetas();
    for (let i = 0; i < metas.length; i++) {
        const meta = metas[i];
        const pt = meta.dataset.interpolate({ x }, "x");
        if (pt) {
            const element = new PointElement({ ...pt, options: { ...indicatorOptions } });
            meta._pt = element;
            items.push({ element, index: -1, datasetIndex: meta.index });
        } else {
            meta._pt = null;
        }
    }
    return items;
};