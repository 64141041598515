import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const history = useNavigate();

    return (
      <Component
        history={history}
        {...props}
      />
    );
  };

  return Wrapper;
};

class ModalDialog extends Component {
  isModal() {
    const { modal } = this.props;
    return modal;
  }

  render() {
    const {
      title, modalCloseTo, show = true,
      modalFooter, children, history, onHide, modalSize, modalCloseButton,showPrimaryBox
    } = this.props;


    return (

      <Modal
        show={show}
        onHide={() => { if (modalCloseTo) { history.push(modalCloseTo); } if (onHide) onHide(); }}
        onExited={() => { if (modalCloseTo) { history.push(modalCloseTo); } }}
        bsSize={modalSize}
        enforceFocus={false}
      >
        <div style={{ padding: '10px' }}>
           <div className={ showPrimaryBox? "box box-primary":""}>
            <Modal.Header closeButton={modalCloseButton}>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '5px' }}>
              {children}
            </Modal.Body>
            {modalFooter && <Modal.Footer>{modalFooter}</Modal.Footer>}
          </div>
        </div>
      </Modal>

    );
  }
}


ModalDialog.propTypes = {
  title: PropTypes.string,
  modalCloseTo: PropTypes.func,
  show: PropTypes.bool,
  showPrimaryBox: PropTypes.bool,
  modalFooter: PropTypes.node,
  children: PropTypes.node,
  history: ReactRouterPropTypes.history,
  onHide: PropTypes.func,
  modalSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  modalCloseButton: PropTypes.bool,
};

ModalDialog.defaultProps = {
  title: null,
  modalCloseTo: null,
  show: true,
  modalFooter: null,
  children: null,
  history: null,
  onHide: null,
  modalSize: null,
  modalType: null,
  modalCloseButton: true,
  showPrimaryBox: true,
};

export default withRouter(ModalDialog);