import { AUTH_TOKEN } from "./constant";
import { jwtDecode } from 'jwt-decode';

export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const isTokenExpired = (token) => { 
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // current time in seconds
    return decoded.exp < currentTime;
  } catch (error) {
    console.log(error);
    return true; // if there's an error, consider the token expired
  }
}

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
};

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
};

export const hasPermission = (permissions, plugin, controller, action) => {
  if (!permissions) {
    return false;
  }

  return (
    permissions[plugin] &&
    permissions[plugin].controllers[controller] &&
    permissions[plugin].controllers[controller][action] &&
    permissions[plugin].controllers[controller][action].enabled
  );
};